@import "../../style/variables";

footer {
  width: 100vw;
  background-color: $bg;

  .footer {
    &__content {
      width: 100%;
      max-width: $max-content-width;
      position: relative;
      margin: 0 auto;

      @media (max-width: 1200px) {
        padding: 0 40px;
        width: calc(100% - 80px);
      }
      @media (max-width: 767px) {
        padding: 0 20px;
        width: calc(100% - 40px);
      }

      &__logoLine {
        display: block;
        margin-bottom: 30px;
        @media (min-width: 1201px) {
          display: none;
        }
      }

      &__data {
        padding: 30px 0 40px 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        max-width: $max-content-width;
        gap: 30px !important;

        @media (max-width: 1200px) {
          width: calc(100% - 80px);
          flex-wrap: wrap;
        }
        @media (max-width: 767px) {
          width: calc(100% - 40px);
          justify-content: space-between;
        }

        &__logos {
          flex-direction: row !important;
          justify-content: flex-end !important;
          flex-wrap: wrap;
          gap: 30px !important;

          > div {
            display: flex;
            flex-direction: column;
            gap: 10px;

            > img {
              height: 27px;
              object-fit: contain;
            }

            > span {
              font-size: 12px;
              text-align: center;
            }
          }

          @media (max-width: 767px) {
            order: 2 !important;
          }
        }
        &__first {
          @media (max-width: 767px) {
            order: 1 !important;
          }
        }
        &__second {
          @media (max-width: 767px) {
            order: 3;
          }
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          flex: 1;
          gap: 12px;

          @media (max-width: 1200px) {
            &:first-child {
              display: none;
            }
          }
          @media (max-width: 767px) {
            width: 40%;
            flex: none;
            order: 3;
          }

          > a {
            color: $lightGreyText;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            > svg {
              fill: $lightGreyText;
            }
          }

          > span {
            color: #ffffff;
            font-size: 16px;
          }
        }
      }

      &__separator {
        position: relative;
        width: 100%;
        max-width: $max-content-width;
        height: 2px;
        background-color: $borderColor;
      }

      &__copyright {
        position: relative;
        width: 100%;
        max-width: $max-content-width;
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        > div {
          display: flex;
          flex: 1;
          flex-direction: column;
          font-size: 12px;
          color: $lightGreyText;
        }

        &__text {
          align-items: center;
          justify-content: center;
        }

        &__docs {
          justify-content: flex-end;
          align-items: flex-end;
          gap: 10px;

          @media (max-width: 767px) {
            justify-content: center;
            align-items: center;
            margin-top: 20px;
          }

          > span {
            cursor: pointer;
            color: $lightGreyText;
          }
        }
      }
    }
  }
}
