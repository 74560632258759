@import "../../style/variables";

.aboutBlock{
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0;

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    max-width: $max-content-width;
    gap: 60px;

    @media (max-width: 1200px) {
      width: calc(100% - 80px);
    }
    @media (max-width: 767px) {
      padding: 0 20px;
      width: calc(100% - 40px);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      > div {
        width: calc(100% - 40px);
      }
    }

    svg {
      width: 350px;
      height: auto;

      @media (max-width: 767px) {
        width: calc(50vw - 20px);
        float: right;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 14px;

      h2 {
        margin-top: 0;
        margin-bottom: 26px;
        font-size: 28px;
        color: #ffffff;
      }
      p {
        font-size: 14px;
        color: $lightGreyText;
        margin: 0;
      }
    }
  }
}
