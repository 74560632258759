// colors
$bg: #151919;
$textColor: #ffffff;
$borderColor: #0F3030;
$lightGreyText: #CCD9D9;
$accent: #1C8785;
$hover: #0F5756;
$errorColor: #D43D3D;

// sizes
$max-content-width: 1200px;

// fonts
@font-face {
  font-family: 'Lato';
  src: url('../asset/font/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Jost';
  src: url('../asset/font/Jost-Regular.ttf') format('truetype');
}
