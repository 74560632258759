@import "../../style/variables";

.rewardsBlock {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 150px;

  @media (max-width: 767px) {
    margin-bottom: 80px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    width: 100vw;
    max-width: $max-content-width;

    @media (max-width: 1200px) {
      padding: 0 40px;
      width: calc(100% - 80px);
    }
    @media (max-width: 767px) {
      padding: 0 20px;
      width: calc(100% - 40px);
    }

    h2 {
      margin: 0;
      font-size: 28px;
    }

    h3 {
      font-size: 16px;
      color: #ffffff;
    }

    &__blocks {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      gap: 26px;

      @media (max-width: 767px) {
        gap: 20px;
        flex-wrap: wrap;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 20px 16px;
        flex: 1 1 100%;
        border-radius: 4px;

        @media (max-width: 767px) {
          flex: none;
          width: calc(50vw - 63px);
        }

        > h3 {
          font-size: 26px;
          line-height: 150%;
          margin: 0;
        }
        > p {
          font-size: 16px;
          color: $lightGreyText;
          margin: 0;
        }

        &:nth-child(2n - 1) {
          background: linear-gradient(to bottom, #151C1C 0%, #054C4B 100%);

          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }
        &:nth-child(2n) {
          border: solid 1px $borderColor;

          @media (max-width: 767px) {
            margin-top: 0;
          }

          > h3 {
            color: $accent;
          }
        }
      }
    }
  }
}
