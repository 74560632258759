@import "../../style/variables";

header {
  height: auto;
  width: 100vw;
  position: relative;
}

.header {
  &__openMenu {
    background-color: $bg;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;

    &__close {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      width: 100%;

      span {
        font-size: 28px;
        color: $textColor;
        font-weight: 400;
        margin-left: 40px;
      }
      svg {
        margin-right: 40px;
      }
    }

    &__points {
      display: block;
      margin: 0 40px 0 40px;
      padding: 0 40px 0 40px;
      width: 100%;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 38px;
        margin: 0 40px;
        border-bottom: solid 2px $borderColor;

        &:hover {
          text-decoration: underline;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__margin {
    height: 120px;
  }

  &__fixed {
    background-color: $bg;
    z-index: 1000;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
      height: 40px;
      max-width: $max-content-width;

      @media (max-width: 1232px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      &__menu {
        display: flex;

        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 28px;

        a {
          font-size: 14px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__burger {
        display: none;
      }

      &__logo, &__phone, &__phone a {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;

        svg {
          fill: #ffffff;
        }
      }
      &__logo {
        justify-content: flex-start;
      }
      &__phone, &__phone a {
        justify-content: flex-end;
        gap: 10px;
        color: $textColor;

        @media (max-width: 480px) {
          span {
            display: none;
          }
        }
      }

      @media (max-width: 900px) {
        &__menu {
          display: none;
        }
        &__burger {
          display: block;
          margin-left: 30px
        }
      }
    }
  }
}
