@import "../../style/variables";

.helloBlock {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;
  background: url(../../asset/images/bgHello.png) no-repeat;
  background-position: 100% 50%;
  background-size: contain;

  @media (max-width: 767px) {
    background-position: 150% 50px;
    background-size: auto 228px;
    margin-bottom: 0px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    max-width: $max-content-width;
    margin: 170px 0;

    @media (max-width: 1200px) {
      padding: 0 40px;
      width: calc(100% - 80px);
      margin: 110px 0;
    }
    @media (max-width: 767px) {
      padding: 0 20px;
      width: calc(100% - 40px);
      margin: 50px 0;
    }

    h1 {
      font-size: 42px;
      line-height: 140%;
      text-align: left;
      text-transform: uppercase;
      color: #ffffff;

      @media (max-width: 1200px) {
        font-size: 24px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        max-width: 50vw;
        margin-bottom: 12px;

        > br {
          display: none;
        }
      }

      > span {
        color: $accent
      }
    }

    > p {
      color: $lightGreyText;
      font-size: 16px;
      max-width: 365px;
      margin-bottom: 70px;

      @media (max-width: 1200px) {
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
        max-width: 50vw;
        font-size: 14px;
        margin-top: 0;
      }
    }

    &__btnLine {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 26px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &__btns {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 0 20px;
      width: calc(100% - 40px);
      margin: 50px 0;

      > .ui__button {
        width: calc(100% - 20px);
      }
    }
  }
}
