@import "../../style/variables";

.variantsBlock {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 55px 0;
  background: linear-gradient(to right, #131B1B 0%, #102323 100%);

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    max-width: 800px;
    width: 60vw;

    @media (max-width: 767px) {
      padding: 0 20px;
      width: calc(100% - 40px);
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      gap: 12px;
      color: #ffffff;
      font-size: 16px;

      > span {
        max-width: 100px;
        text-align: center;
      }
    }
  }
}
