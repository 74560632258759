@import "style/variables";
@import "style/atom";

body, .App {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: $bg;
  font-family: Lato, serif;
}

a, a:hover, a:active, a:visited {
  color: $textColor;
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
}

.ui {
  &__button {
    border: solid 1px $accent;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 16px 32px;
    background-color: $accent;
    cursor: pointer;

    &:hover {
      background-color: $hover;
      border-color: $hover;
    }
    &:active {
      background-color: $accent;
      border-color: $accent;
    }
  }
  &__input {
    border: solid 1px $accent;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    padding: 16px 16px;
    background: $bg;

    &:focus {
      outline: none;
    }
  }
  &__collapse {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    gap: 0;

    &__header {
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 1 1;
      width: calc(100% - 32px);
      gap: 20px;
      border-radius: 4px;
      border: solid 1px $borderColor;
      padding: 20px 16px;

      > div {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        stroke: #ffffff;
        width: 18px !important;
        height: 18px !important;
      }

      &.opened {
        background: $borderColor;
        border-radius: 4px 4px 0 0;

        > div {
          background: #ffffff;
        }

        svg {
          stroke: $borderColor;
        }
      }
    }
    &__content {
      color: $lightGreyText;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1 1;
      width: calc(100% - 32px);      background: $borderColor;
      border-radius: 0 0 4px 4px;
      padding: 0 16px 20px 16px;
    }
  }
}

.size-18 {
  width: 18px;
  height: 18px;
}

h1, h2, h3 {
  font-family: Jost, serif;
  color: #ffffff;
  text-transform: uppercase;
}

.anchor {
  position: relative;
  top: -120px;
}
