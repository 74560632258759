@import "../../style/variables";

.FAQBlock {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;

  @media (max-width: 767px) {
    margin-bottom: 80px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    width: 100vw;
    max-width: $max-content-width;

    @media (max-width: 1200px) {
      padding: 0 40px;
      width: calc(100% - 80px);
    }
    @media (max-width: 767px) {
      padding: 0 20px;
      width: calc(100% - 40px);
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        width: calc(100% - 40px);

        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 1;
        }
      }
    }

    svg {
      width: 246px;
      height: auto;

      @media (max-width: 767px) {
        width: calc(50vw - 20px);
        float: right;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      gap: 14px;

      h2 {
        margin-top: 0;
        margin-bottom: 26px;
        font-size: 28px;
        color: #ffffff;
      }
    }
  }
}
