@import "../../style/variables";

.contactUsForm {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;

  @media (max-width: 767px) {
    margin-bottom: 80px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 380px;

    h2 {
      margin: 0;
    }
    p {
      color: $lightGreyText;
      font-size: 14px;
      margin: 0;
    }

    form {
      width: 309px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .ui__button {
        width: 243px;
      }
      .ui__input {
        width: 275px;
      }

      span {
        color: $lightGreyText;
        text-align: center;

        &.error {
          color: $errorColor;
        }

        a {
          cursor: pointer;
          color: $accent;
        }
      }
    }

    &__doneBlock {
      background: linear-gradient(to bottom, #151C1C 0%, #054C4B 100%);
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      text-align: center;
    }
  }
}
